<script>
import { 
  type_document_identifications, type_organizations, type_regimes, type_liabilities, municipalities
} from './mocks.json'

export default {
  data() {
    return {
      type_document_identifications: type_document_identifications,
      type_organizations: type_organizations,
      type_regimes: type_regimes,
      type_liabilities: type_liabilities,
      municipalities: municipalities
    }
  },
  methods: {
    async primeraConfig( event ) {
      const fields = Object.fromEntries(new FormData(event.target));
      console.log(fields);

      // Esto no funciona ya que el dominio no es seguro
      // const response = await fetch(`http://64.23.244.187/api/ubl2.1/config/${fields.nit}/${fields.dv}`, {
      //   method: 'POST',
      //   body: JSON.stringify({
      //     type_document_identification_id: fields.type_document_identification_id,
      //     type_organization_id: fields.type_organization_id,
      //     type_regime_id: fields.type_regime_id,
      //     type_liability_id: fields.type_liability_id,
      //     business_name: fields.business_name,
      //     merchant_registration: fields.merchant_registration,
      //     municipality_id: fields.municipality_id,
      //     address: fields.address,
      //     phone: fields.phone,
      //     email: fields.email
      //   }),
      //   headersList: {
      //     "Accept": "*/*",
      //     'Content-Type': 'application/json'
      //   }
      // })

      // const result = await response.json();

      // console.log(result);



      Object.keys(fields).forEach( key => {
        const input = document.querySelector(`[name="${key}"]`);
        if (input) {
          input.value = null;
        }
      });
    }
  },
  mounted() {
    const dataInject = {
      nit: 901385215,
      dv: 7,
      type_document_identification_id: 6,
      type_organization_id: 1,
      type_regime_id: 1,
      type_liability_id: 14,
      business_name: 'Prueba',
      merchant_registration: '0000000-00',
      municipality_id: 149,
      address: 'CL 127 A 53 A 45 TO 2 OF 6 102',
      phone: 3107932844,
      email: 'nomina@helexium.com'
    }

    Object.keys(dataInject).forEach( key => {
      const input = document.querySelector(`[name="${key}"]`);
      if (input) {
        input.value = dataInject[key];
      }
    });
  }

}
</script>

<template>
  <div>
    <h3>Registrar de la empresa</h3>
    
    <form @submit.prevent="primeraConfig">

      <div class="wrapper-fields">
        <div class="wrapper-input" title="Número de Identificación Tributaria">
          <label for="nit">NIT</label>
          <b-form-input id="nit" name="nit" required></b-form-input>
        </div>

        <div class="wrapper-input" title="Dígito de verificación">
          <label for="dv">DV</label>
          <b-form-input id="dv" name="dv" required></b-form-input>
        </div>

        <div class="wrapper-input">
          <label for="type_document_identifications">Tipo de documento de identificación</label>
          <HelexSelect name="type_document_identification_id" :options="type_document_identifications"
            optionLabel="name" optionValue="id"></HelexSelect>
        </div>

        <div class="wrapper-input">
          <label for="type_organizations">Tipo de organización</label>
          <HelexSelect name="type_organization_id" :options="type_organizations" optionLabel="name" optionValue="id">
          </HelexSelect>
        </div>

        <div class="wrapper-input">
          <label for="type_regimes">Tipo de régimen</label>
          <HelexSelect name="type_regime_id" :options="type_regimes" optionLabel="name" optionValue="id">
          </HelexSelect>
        </div>

        <div class="wrapper-input">
          <label for="type_liabilities">Tipo de responsabilidad</label>
          <HelexSelect name="type_liability_id" :options="type_liabilities" optionLabel="name" optionValue="id">
          </HelexSelect>
        </div>

        <div class="wrapper-input" title="Nombre de la empresa">
          <label for="business_name">Nombre de la empresa</label>
          <b-form-input id="business_name" name="business_name" required></b-form-input>
        </div>

        <div class="wrapper-input" title="Matricula mercantil">
          <label for="merchant_registration">Matricula mercantil</label>
          <b-form-input id="merchant_registration" name="merchant_registration" required></b-form-input>
        </div>

        <div class="wrapper-input">
          <label for="municipalities">Municipio</label>
          <HelexSelect name="municipality_id" :options="municipalities" optionLabel="name" optionValue="id">
          </HelexSelect>
        </div>

        <div class="wrapper-input" title="Dirección de la empresa">
          <label for="address">Dirección</label>
          <b-form-input id="address" name="address" required></b-form-input>
        </div>

        <div class="wrapper-input" title="Teléfono de contacto">
          <label for="phone">Teléfono</label>
          <b-form-input id="phone" name="phone" required></b-form-input>
        </div>

        <div class="wrapper-input" title="Correo de contacto">
          <label for="email">Correo</label>
          <b-form-input id="email" name="email" required></b-form-input>
        </div>
      </div>
      <vs-button  >Guardar</vs-button>
    </form>
    
  </div>
</template>

<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr) );
  gap: 1rem;
}
</style>